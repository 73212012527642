import { forwardRef, DetailedHTMLProps, ButtonHTMLAttributes, useMemo } from "react";
import { Spin } from "./Spin";

export interface AppButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  textClassName?: string;
  variant?: "text" | "contained" | "outlined" | "plain";
  loading?: boolean;
  disableBorder?: boolean;
}

export const AppButton = forwardRef<HTMLButtonElement, AppButtonProps>(
  (
    {
      children,
      disabled,
      className,
      textClassName,
      disableBorder,
      loading = false,
      variant = "outlined",
      style,
      ...rest
    },
    ref
  ) => {
    const btnStyle = useMemo(() => {
      if (disabled) {
        return {
          cursor: "not-allowed",
          color: "#9D9D9D",
          background: "#e9e9e9",
        };
      }

      if (variant === "contained") {
        return {
          cursor: loading ? "not-allowed" : "pointer",
          color: "#FFFFFF",
          background: "linear-gradient(341deg, #FF99B9 8.41%, #D030DD 91.59%)",
        };
      }

      if (variant === "plain") {
        return {
          cursor: loading ? "not-allowed" : "pointer",
          color: "#302B3C",
          background: "#F2F4FA",
        };
      }

      if (variant === "outlined" && !disableBorder) {
        return {
          cursor: loading ? "not-allowed" : "pointer",
          background:
            "linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(341deg, #FF99B9 8.41%, #D030DD 91.59%) border-box",
          border: "1px solid transparent",
        };
      }

      if (variant === "text") {
        return {
          cursor: loading ? "not-allowed" : "pointer",
        };
      }

      return {};
    }, [disabled, disableBorder, loading, variant]);

    const textStyle = useMemo(() => {
      if (disabled) {
        return {
          color: "#9D9D9D",
        };
      }

      if (variant === "contained") {
        return {
          color: "#FFFFFF",
        };
      }

      if (variant === "outlined") {
        return {
          background: "linear-gradient(341deg, #FF99B9, #D030DD)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
        };
      }

      if (variant === "text") {
        return {
          color: "#302B3C",
        };
      }
    }, [disabled, variant]);

    return (
      <button
        {...rest}
        ref={ref}
        disabled={disabled || loading}
        style={{ ...style, ...btnStyle }}
        className={`relative ${disabled || loading ? "" : "clickable"} ${className}`}
      >
        {loading && (
          <div className="absolute top-0 bottom-0 left-0 right-0 m-auto h-5 w-5">
            <Spin />
          </div>
        )}
        {typeof children === "string" ? (
          <span style={textStyle} className={textClassName}>
            {children}
          </span>
        ) : (
          children
        )}
      </button>
    );
  }
);
