import { createContext, PropsWithChildren, useState, Dispatch, SetStateAction } from "react";
import type { PublishEditData } from "src/types";

interface WidgetCtx {
  publishEditData: PublishEditData[];
  updatePublishEditData: Dispatch<SetStateAction<PublishEditData[]>>;
}

const defaultValue: WidgetCtx = {
  publishEditData: [],
  updatePublishEditData: () => undefined,
};

export const WidgetContext = createContext(defaultValue);

export const WidgetProvider = ({ children }: PropsWithChildren<{}>) => {
  const [publishEditData, setPublishEditData] = useState<PublishEditData[]>([]);

  return (
    <WidgetContext.Provider value={{ publishEditData, updatePublishEditData: setPublishEditData }}>
      {children}
    </WidgetContext.Provider>
  );
};
