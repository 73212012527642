import { Suspense, useState } from "react";
import { AppSidebar } from "src/components/AppSidebar";
import { AppHeader } from "src/components/AppHeader";
import { WidgetBanner } from "src/components/WidgetBanner";
import { Outlet } from "react-router-dom";
import { useAnchorScroll } from "src/hooks";
// import { AppSpin } from "./components/AppSpin";

const AppLayout = () => {
  useAnchorScroll();
  const [bannerRef, setBannerRef] = useState<HTMLDivElement | null>(null); // !! use useState instead of useRef here

  return (
    <>
      {/* PC */}
      <div className="block h-screen">
        {/* <WidgetBanner ref={setBannerRef} /> */}
        <main className="flex flex-col" style={{ height: `calc(100vh - ${bannerRef?.offsetHeight || 0}px)` }}>
          <AppHeader />
          <div className="flex flex-1">
            <AppSidebar className="hidden md:block h-screen" />
            <div className="bg-b4 w-full p-large h-full overflow-y-auto">
              <Suspense
                fallback={null}
                // fallback={
                //   <div className="w-full h-full flex items-center justify-center">
                //     <AppSpin />
                //   </div>
                // }
              >
                <Outlet />
              </Suspense>
            </div>
          </div>
        </main>
      </div>

      {/* Mobile */}
      {/* <div className="md:hidden p-large">
        <span className="text-semibold text-lg">For a better experience, please visit via PC.</span>
      </div> */}
    </>
  );
};

export default AppLayout;
