import { Link } from "react-router-dom";

const App404Page = () => (
  <div className="w-screen h-screen flex items-center justify-center">
    <div className="flex flex-col gap-large">
      <h3 className="text-semibold text-base">Not Found</h3>
      <Link to="/">Home</Link>
    </div>
  </div>
);

export default App404Page;
