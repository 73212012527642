import { request } from "src/utils";
import type { KeypairType } from "@polkadot/util-crypto/types";

export const apiGetNonce = (params: { public_key: string }) =>
  request({
    url: "/open/v1/user/nonce",
    method: "GET",
    params: { public_key: params.public_key },
  });

export const apiUpdateWeb3 = (params: {
  bind: string;
  encryption: string;
  nonce: string;
  public_key: string;
  signature: string;
}) =>
  request({
    url: "/open/v1/user/update/web3",
    method: "POST",
    data: {
      ...params,
    },
  });

export const apiSignup = ({
  recaptcha,
  ...rest
}: {
  mode: number;
  recaptcha: string;
  email?: string;
  password?: string;
  encryption?: KeypairType;
  nonce?: string;
  public_key?: string;
  signature?: string;
}) =>
  request({
    url: `/open/v1/user/signup?recaptcha=${recaptcha}`,
    method: "POST",
    data: {
      ...rest,
    },
  });

export const apiLogin = (params: {
  email?: string;
  password?: string;
  mode: number;
  encryption?: KeypairType;
  nonce?: string;
  public_key?: string;
  signature?: string;
}) =>
  request({
    url: "/open/v1/user/login",
    method: "POST",
    data: {
      ...params,
    },
  });

export const apiGetUserInfo = () =>
  request({
    url: "/open/v1/user/info",
    method: "GET",
  });

export const apiUpdateUserInfo = (params: {
  language?: string;
  currency?: string;
  time_zone?: string;
  page_size?: number;
  is_show_ads?: boolean;
  is_public?: boolean;
  avatar?: string;
  web?: string;
  username?: string;
  bio?: string;
  legal_name?: string;
}) =>
  request({
    url: "/open/v1/user/info",
    method: "POST",
    data: {
      ...params,
    },
  });

export const apiUpdateSocialAccount = (params: { type: string; username?: string }) =>
  request({
    url: "/open/v1/user/social_account",
    method: "POST",
    data: {
      ...params,
    },
  });

export const apiDeleteSocialAccount = (params: { type: string }) =>
  request({
    url: "/open/v1/user/social_account",
    method: "DELETE",
    data: {
      ...params,
    },
  });

export const apiResendEmail = (params: { email: string; recaptcha: string }) =>
  request({
    url: "/open/v1/user/verify/email/send",
    method: "GET",
    params: {
      email: params.email,
      recaptcha: params.recaptcha,
    },
  });

export const apiUnlinkWeb3 = (params: { bind: string }) =>
  request({
    url: "/open/v1/user/update/web3",
    method: "POST",
    data: {
      bind: params.bind,
    },
  });

export const apiUnlinkEmail = (params: { email: string; binding: string }) =>
  request({
    url: `/open/v1/user/update/email?email=${params.email}&binding=${params.binding}`,
    method: "POST",
  });

export const apiDeleteAccount = (params: {
  mode: number;
  encryption?: KeypairType;
  nonce?: string;
  public_key?: string;
  signature?: string;
}) =>
  request({
    url: "/open/v1/user/delete",
    method: "POST",
    data: {
      ...params,
    },
  });

export const apiVerifyToken = (params: { token: string; mode: number }) =>
  request({
    url: "/open/v1/user/check/token",
    method: "GET",
    params: {
      token: params.token,
      mode: params.mode,
    },
  });

export const apiVerifyDelete = (params: { token: string }) =>
  request({
    url: `/open/v1/user/delete/verify?token=${params.token}`,
    method: "POST",
  });

export const apiSendEmail = (params: { email: string; binding: string }) =>
  request({
    url: `/open/v1/user/update/email?email=${params.email}&binding=${params.binding}`,
    method: "POST",
  });

export const apiSetPassword = (params: { password: string }) =>
  request({
    url: "/open/v1/user/password",
    method: "PUT",
    data: {
      password: params.password,
    },
  });

export const apiUpdatePassword = (params: { old_password: string; password: string }) =>
  request({
    url: "/open/v1/user/verify/reset/password",
    method: "POST",
    data: {
      old_password: params.old_password,
      password: params.password,
    },
  });

export const apiResetPassword = (params: { email: string; recaptcha: string }) =>
  request({
    url: `/open/v1/user/forget/password/send`,
    method: "GET",
    params: {
      email: params.email,
      recaptcha: params.recaptcha,
    },
  });

export const apiVerifyPassword = (params: { token: string; password: string }) =>
  request({
    url: "/open/v1/user/update/password",
    method: "POST",
    data: {
      token: decodeURI(params.token),
      password: params.password,
    },
  });

export const apiVerifyEmail = (params: { token: string }) =>
  request({
    url: `/open/v1/user/verify/email?token=${encodeURI(params.token)}`,
    method: "POST",
  });

export const apiGetUserDashboard = () =>
  request({
    url: "/open/v1/user/dashboard",
    method: "GET",
  });

export const apiUserLogout = () =>
  request({
    url: "/open/v1/user/logout",
    method: "GET",
  });
