import axios from "axios";
import config from "src/config/config";
import { storeGetOrganizationId } from "./store";

const instance = axios.create({
  baseURL: config.baseUrl,
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.request.use((config) => {
  const organization = storeGetOrganizationId();

  config.headers = {
    ...config.headers,
    ...(organization ? { "OPEN-PLATFORM-ORGANIZATION": organization } : {}),
  };

  return config;
});

export const request = instance;
