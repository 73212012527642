import { useEffect } from "react";
import { AppButton } from "./AppButton";
import { AppInput } from "./AppInput";

export const AppEmailSubscribe = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <form
      className="flex flex-col items-start gap-small"
      action="https://network.us6.list-manage.com/subscribe/post?u=eb1c779b75a344e2d52755879&amp;id=70a65557b6"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
    >
      <div className="mc-field-group flex items-center">
        <AppInput placeholder="name@email.com" id="mce-EMAIL" name="EMAIL" />
        <AppButton className="h-9 rounded px-large -ml-1" variant="contained" type="submit">
          Get Updates
        </AppButton>
      </div>
      <label
        htmlFor="mce-EMAIL"
        style={{ display: "none" }}
        className={`mce_inline_error text-normal text-xs text-red-600`}
      />
      <span id="mce-error-response" className="hidden text-normal text-xs text-red-600" />
      <span id="mce-success-response" className="hidden text-normal text-xs text-red-600" />
    </form>
  );
};
