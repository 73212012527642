import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import reportWebVitals from "./reportWebVitals";
import "src/styles/app.css";
import "src/styles/lowcode.css";
import "./aos";
import config from "src/config/config";
import ReactGA from "react-ga4";

import { router } from "./router";
import { ApiProvider, AppProvider, WidgetProvider } from "./providers";
import { AppSpin } from "./components/AppSpin";
const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
if (process.env.REACT_APP_ENV === "production") {
  ReactGA.initialize([
    {
      trackingId: "G-XFF61XBJVV",
      gaOptions: {
        cookieDomain: "pro.subscan.io",
      },
    },
  ]);
}
root.render(
  <StrictMode>
    <Suspense
      fallback={
        <div className="w-screen h-screen flex items-center justify-center">
          <AppSpin />
        </div>
      }
    >
      <GoogleReCaptchaProvider
        reCaptchaKey={config.recaptchaKey}
        scriptProps={{
          async: true,
        }}
      >
        <ApiProvider>
          <AppProvider>
            <WidgetProvider>
              <RouterProvider router={router} />
            </WidgetProvider>
          </AppProvider>
        </ApiProvider>
      </GoogleReCaptchaProvider>
    </Suspense>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
