import clsx from "clsx";
import React, { HTMLAttributes } from "react";

const Boundary: React.FC<HTMLAttributes<HTMLDivElement> & { noPadding?: boolean }> = ({
  children,
  className,
  noPadding,
  ...props
}) => (
  <div
    className={clsx("rounded bg-white border border-b4 shadow-module", { "p-2 lg:p-large": !noPadding }, className)}
    {...props}
  >
    {children}
  </div>
);

export default Boundary;
