import { CSSProperties, PropsWithChildren, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppDrawer } from "../components/AppDrawer";
import { AppButton } from "../components/AppButton";
import logo from "src/assets/images/app-logos/open_platform_logo_4.svg";
import logoM from "src/assets/images/app-logos/open_platform_logo_3.svg";
import menuIcon from "src/assets/images/icon_menu.svg";
import donateIcon from "src/assets/images/icon_donate.svg";
import twitterIcon from "src/assets/images/icon_twitter.svg";
import githubIcon from "src/assets/images/icon_github.svg";
import elementIcon from "src/assets/images/icon_element.svg";
import mediumIcon from "src/assets/images/icon_medium.svg";
import emailIcon from "src/assets/images/icon_email.svg";
import { User } from "src/components/User";
import { useApp } from "../hooks";
import { AppEmailSubscribe } from "../components/AppEmailSubscribe";
import { AppMenu } from "../components/AppMenu";

const AppHomePageLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const { userInfo } = useApp();
  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState<CSSProperties>({ background: "transparent" });

  useEffect(() => {
    const listener = () => {
      if (window.scrollY >= 10) {
        setStyle({ background: "linear-gradient(340.88deg, rgba(254, 56, 118, 0.619832) 8.41%, #D030DD 91.59%)" });
      } else {
        setStyle({ background: "transparent" });
      }
    };

    window.addEventListener("scroll", listener, false);
    return () => window.removeEventListener("scroll", listener, false);
  }, []);

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-30 h-[3.125rem]" style={style}>
        {/* mobile */}
        <div className="sm:hidden container m-auto flex items-center justify-between h-full px-middle">
          <Link to="/" className="transition-transform hover:scale-105 active:scale-95">
            <img alt="..." src={logoM} />
          </Link>
          <button
            className="hover:outline-none focus-visible:outline-none w-fit h-fit transition-transform active:scale-95"
            onClick={() => setOpen(true)}
          >
            <img alt="..." src={menuIcon} />
          </button>
        </div>

        {/* pc */}
        <div
          className="hidden sm:flex container m-auto items-center justify-between h-full px-middle"
          style={{ maxWidth: 1200 }}
        >
          <Link to="/" className="transition-transform hover:scale-105 active:scale-95">
            <img alt="..." src={logo} />
          </Link>

          <div className="flex items-center gap-x-10">
            <AppMenu />

            {!userInfo ? (
              <div className="flex items-center gap-large">
                <Link
                  to="/login"
                  className="text-white text-normal text-sm transition-opacity hover:opacity-80 active:opacity-60"
                >
                  Login
                </Link>
                <AppButton
                  className="h-[25px] flex items-center px-large rounded bg-white"
                  textClassName="text-normal text-sm"
                  disableBorder
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  Sign up
                </AppButton>
              </div>
            ) : (
              <User disableBorder />
            )}
          </div>
        </div>
      </div>

      <AppDrawer title="menu" open={open} maskClosable onClose={() => setOpen(false)}>
        <div className="h-full flex flex-col items-start justify-between">
          <div className="w-full py-10 px-4 overflow-y-auto">
            <AppMenu onFinish={() => setOpen(false)} />
          </div>
          <div className="flex justify-center w-full">
            <Media />
          </div>
        </div>
      </AppDrawer>
    </>
  );
};

const Footer = () => {
  const [hideFade, setHideFade] = useState(true);

  useEffect(() => {
    const listener = (ev: MediaQueryListEvent) => {
      setHideFade(!ev.matches);
    };
    const mq = window.matchMedia("(min-width: 1024px)");
    mq.addEventListener("change", listener, false);
    return () => {
      mq.removeEventListener("change", listener);
    };
  }, []);

  return (
    <>
      <div data-aos={hideFade ? "" : "fade-up"} className="px-middle bg-b4 lg:bg-transparent relative overflow-hidden">
        <div
          className="absolute hidden opacity-10 rounded-full bg-gradient2 lg:block lg:-right-32 lg:-bottom-48 lg:w-[540px] lg:h-[420px] -z-10"
          style={{
            filter: "blur(67.957px)",
            mixBlendMode: "normal",
          }}
        />

        <div className="container m-auto lg:px-middle" style={{ maxWidth: 1200 }}>
          <div className="hidden lg:flex justify-between pb-[60px]">
            {/* Subscribe */}
            <div className="flex flex-col gap-[30px]">
              <span className="text-semibold text-lg">Subscribe</span>
              <p className="text-normal text-sm">
                Subscribe to the newsletter to hear about Subscan updates and events.
              </p>

              {/* email subscribe */}
              <AppEmailSubscribe />

              {/* media */}
              <Media />
            </div>

            {/* Usefull links */}
            <div className="flex gap-[100px]">
              <UsefullLinkItem
                title="Explorers"
                values={[
                  { text: "Polkadot", link: "https://polkadot.subscan.io/" },
                  { text: "Kusama", link: "https://kusama.subscan.io/" },
                  { text: "Astar", link: "https://astar.subscan.io/" },
                  { text: "Moonbeam", link: "https://moonbeam.subscan.io/" },
                  { text: "More >", link: "https://www.subscan.io/network_list/" },
                ]}
              />
              <UsefullLinkItem
                title="Products & Services"
                values={[
                  { text: "Explorer-as-a-Service", link: "https://subscan.typeform.com/to/jqdAWJ" },
                  { text: "Developer APIs", link: "https://docs.api.subscan.io/" },
                  { text: "Multisig Tools", link: "https://multisig.subscan.io/" },
                  { text: "Account Format Transform", link: "https://polkadot.subscan.io/tools/format_transform" },
                  { text: "Analytics Charts", link: "https://polkadot.subscan.io/tools/charts" },
                  { text: "Service Status", link: "https://subscan.statuspage.io/" },
                ]}
              />
              <UsefullLinkItem
                title="Supports"
                values={[
                  { text: "Privacy Policy", link: "https://www.subscan.io/privacy" },
                  { text: "Terms of Use", link: "https://www.subscan.io/term" },
                  { text: "Open Source Notices", link: "https://www.subscan.io/open-source-notices" },
                  { text: "Brand Assets", link: "https://www.subscan.io/website/home/subscan_brand.zip" },
                  { text: "Version History", link: "https://medium.com/subscan/tagged/subscan-update" },
                  { text: "Feedback", link: "https://github.com/itering/subscan-issue-tracker/issues/new/choose" },
                ]}
              />
            </div>
          </div>

          <div className="flex items-center justify-between h-10 lg:h-16 lg:border-t lg:border-t-b3">
            <div className="flex items-center justify-between w-full lg:w-auto lg:justify-start lg:gap-large">
              <span className="text-b2 lg:text-black text-normal text-xs">
                {`Subscan © ${new Date().getFullYear()} - Developed By Subscan Team`}
              </span>
              <a
                href="https://www.subscan.io/donate"
                target={"_blank"}
                rel="noopener noreferrer"
                className="transition hover:scale-105 active:scale-95 lg:hover:scale-100 lg:after:scale-100"
              >
                <img alt="" src={donateIcon} className="lg:hidden" />
                <AppButton
                  className="hidden lg:flex items-center justify-center h-6 px-middle rounded"
                  textClassName="text-normal text-sm"
                >
                  Donate
                </AppButton>
              </a>
            </div>

            <div className="hidden lg:flex items-center gap-large">
              <a
                target={"_blank"}
                rel="noopener noreferrer"
                href="https://www.subscan.io/privacy"
                className="text-black text-normal text-xs hover:underline"
              >
                Privacy Policy
              </a>
              <a
                target={"_blank"}
                rel="noopener noreferrer"
                href="https://www.subscan.io/term"
                className="text-black text-normal text-xs hover:underline"
              >
                Terms Of Use
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Media = () => (
  <div className="flex items-center gap-[15px]">
    <a
      className="transition-transform hover:scale-105 active:scale-95 w-5 h-5 flex items-center justify-center"
      target={"_blank"}
      rel="noopener noreferrer"
      href="https://twitter.com/subscan_io/"
    >
      <img alt="" src={twitterIcon} />
    </a>
    <a
      className="transition-transform hover:scale-105 active:scale-95 w-5 h-5 flex items-center justify-center"
      target={"_blank"}
      rel="noopener noreferrer"
      href="https://github.com/subscan-explorer"
    >
      <img alt="" src={githubIcon} />
    </a>
    <a
      className="transition-transform hover:scale-105 active:scale-95 w-5 h-5 flex items-center justify-center"
      target={"_blank"}
      rel="noopener noreferrer"
      href="https://riot.im/app/#/room/!uaYUrKBueiKUurHliJ:matrix.org?via=matrix.org&via=matrix.parity.io&via=web3.foundation"
    >
      <img alt="" src={elementIcon} />
    </a>
    <a
      className="transition-transform hover:scale-105 active:scale-95 w-5 h-5 flex items-center justify-center"
      target={"_blank"}
      rel="noopener noreferrer"
      href="https://medium.com/subscan"
    >
      <img alt="" src={mediumIcon} />
    </a>
    <a
      className="transition-transform hover:scale-105 active:scale-95 w-5 h-5 flex items-center justify-center"
      href="mailto:hello@subscan.io"
    >
      <img alt="" src={emailIcon} />
    </a>
  </div>
);

const UsefullLinkItem = ({ title, values }: { title: string; values: { text: string; link: string }[] }) => (
  <div className="flex flex-col gap-[30px]">
    <h5 className="text-semibold text-lg">{title}</h5>
    <div className="flex flex-col gap-middle">
      {values.map(({ text, link }, index) => (
        <a
          key={index}
          className="text-black text-normal text-xs transition-transform hover:underline"
          target={"_blank"}
          rel="noopener noreferrer"
          href={link}
        >
          {text}
        </a>
      ))}
    </div>
  </div>
);

export default AppHomePageLayout;
