import type { Modifier } from "react-popper/typings/react-popper";

export const sameWidth = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
  },
} as Modifier<string>;
