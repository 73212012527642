export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case "USD":
      return "$";
    case "CNY":
      return "¥";
    case "EUR":
      return "€";
    default:
      return "$";
  }
};
