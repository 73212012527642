import { createContext, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { ApiPromise, WsProvider } from "@polkadot/api";
import { web3Enable, web3Accounts } from "@polkadot/extension-dapp";
import { encodeAddress } from "@polkadot/util-crypto";
import type { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";
import { from } from "rxjs";
import { storeSaveWalletAccess } from "src/utils";
import { notification } from "src/utils/notification";
import config from "src/config/config";

const DAPP_NAME = "subscan/open-platform";

interface ApiCtx {
  api: ApiPromise | null;
  accounts: InjectedAccountWithMeta[];
  connectWallet: () => Promise<void>;
}

const defaultValue: ApiCtx = {
  api: null,
  accounts: [],
  connectWallet: async () => undefined,
};

export const ApiContext = createContext<ApiCtx>(defaultValue);

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [api, setApi] = useState<ApiPromise | null>(null);
  const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);

  const connectWallet = useCallback(async () => {
    try {
      const extensions = await web3Enable(DAPP_NAME);

      if (extensions.length) {
        const accs = await web3Accounts();
        if (accs.length) {
          setAccounts(
            accs
              .filter((acc) => acc.type === "ed25519" || acc.type === "sr25519")
              .map((acc) => ({ ...acc, address: encodeAddress(acc.address, 0) }))
          );
        } else {
          notification.warn({
            title: "Connection failed",
            description: "No available account",
          });
        }
        storeSaveWalletAccess(true);
      } else {
        notification.warn({
          title: "Connection failed",
          description: (
            <>
              <p className="text-normal text-sm">Polkadot-js extension not detected.</p>
              <p className="text-normal text-sm">No web3 account integration could be found.</p>
              <p className="text-normal text-sm">
                Please visit this page on a computer with{" "}
                <a
                  className="text-link clickable"
                  href="https://polkadot.js.org/extension/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  polkadot-js extension
                </a>
                .
              </p>
            </>
          ),
        });
        storeSaveWalletAccess(false);
      }
    } catch (err) {
      console.error("connect wallet:", err);
    }
  }, []);

  useEffect(() => {
    const sub$$ = from(ApiPromise.create({ provider: new WsProvider(config.rpc) })).subscribe({
      next: (api) => {
        setApi(api);
      },
      error: (error) => {
        console.error("init api instance:", error);
      },
    });

    return () => {
      sub$$.unsubscribe();
      setApi(null);
    };
  }, []);

  return <ApiContext.Provider value={{ api, accounts, connectWallet }}>{children}</ApiContext.Provider>;
};
