import { useEffect, ReactNode } from "react";
import { useRouteError, Link } from "react-router-dom";

interface Props {
  title?: ReactNode;
  message?: ReactNode;
}

export const ErrorCatcher = ({ title = "Oops!", message = "Sorry, an unexpected error has occurred." }: Props) => {
  const error = useRouteError();

  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="flex flex-col gap-large">
        <h3 className="text-semibold text-base">{title}</h3>
        <span className="text-normal text-sm">{message}</span>
        <Link to="/">Home</Link>
      </div>
    </div>
  );
};
