import { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

interface MenuCfg {
  text: string;
  path?: string;
  isExternal?: boolean;
  subMenu?: MenuCfg[];
}

const menuConfig: MenuCfg[] = [
  { text: "Home", path: "/" },
  { text: "Overview", path: "/overview" },
  {
    text: "Products",
    subMenu: [
      { text: "Api Service", path: "/api_service" },
      { text: "Widgets", path: "/widgets" },
    ],
  },
  { text: "Pricing", path: "/pricing" },
  // { text: "Use Case", path: "/" },
  {
    text: "Developers",
    subMenu: [
      { text: "API Docs", path: "https://docs.api.subscan.io/", isExternal: true },
      { text: "Widget Docs", path: "https://github.com/subscan-explorer/widget-interface", isExternal: true },
      { text: "Service Status", path: "https://subscan.statuspage.io/", isExternal: true },
      // { text: "Widget Editor", path: "/" },
    ],
  },
  // {
  //   text: "Resources",
  //   subMenu: [
  //     { text: "Resources 01", path: "/" },
  //     { text: "Resources 02", path: "/" },
  //     { text: "Resources 03", path: "/" },
  //     { text: "Resources 04", path: "/" },
  //   ],
  // },
];

export const AppMenu = ({ onFinish = () => undefined }: { onFinish?: () => void }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [expandedIndex, setExpandedIndex] = useState(-1);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (e.target && ref.current && !ref.current.contains(e.target as Node)) {
        setExpandedIndex(-1);
      }
    };

    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("click", listener);
    };
  }, []);

  return (
    <div className="flex flex-col gap-6 sm:flex-row sm:gap-large" ref={ref}>
      {menuConfig.map(({ text, path, isExternal, subMenu }, index) => {
        if (path) {
          return isExternal ? (
            <a
              key={text}
              target={"_blank"}
              href={path}
              rel="noreferrer"
              className="text-semibold text-base sm:text-normal sm:text-sm sm:text-white"
              onClick={() => {
                setExpandedIndex(-1);
                onFinish();
              }}
            >
              {text}
            </a>
          ) : (
            <Link
              key={text}
              to={path}
              className="text-semibold text-base sm:text-normal sm:text-sm sm:text-white sm:first:hidden"
              onClick={() => {
                setExpandedIndex(-1);
                onFinish();
              }}
            >
              {text}
            </Link>
          );
        } else if (subMenu) {
          return (
            <SubMenu
              key={text}
              text={text}
              subMenu={subMenu}
              expanded={expandedIndex === index}
              onFinish={onFinish}
              onToggle={() => setExpandedIndex((prev) => (prev === index ? -1 : index))}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

const SubMenu = ({
  text,
  subMenu,
  expanded,
  onFinish,
  onToggle,
}: {
  text: string;
  subMenu: MenuCfg[];
  expanded?: boolean;
  onFinish: () => void; // close drawer
  onToggle?: () => void; // close or open sub menu
}) => {
  const nodeRef = useRef(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 12],
        },
      },
    ],
  });

  return (
    <>
      {/* mobile */}
      <div className="flex flex-col items-start gap-6 sm:hidden">
        <button
          className="hover:outline-none focus-visible:outline-none flex items-center justify-between w-full"
          onClick={onToggle}
        >
          <span className="text-semibold text-base">{text}</span>
          <Arraw expanded={expanded} />
        </button>
        {expanded && (
          <div className="flex flex-col gap-large ml-[30px]">
            {subMenu.map(({ text: subText, path: subPath, isExternal: subIsExternal }) => {
              if (subPath) {
                return subIsExternal ? (
                  <a
                    key={subText}
                    target="_blank"
                    href={subPath}
                    rel="noreferrer"
                    className="text-normal text-xs"
                    onClick={onFinish}
                  >
                    {subText}
                  </a>
                ) : (
                  <Link key={subText} to={subPath} className="text-normal text-xs" onClick={onFinish}>
                    {subText}
                  </Link>
                );
              }
              return null;
            })}
          </div>
        )}
      </div>

      {/* pc */}
      <button
        className="hover:outline-none focus-visible:outline-none items-center gap-small hidden sm:flex"
        onClick={onToggle}
        ref={setReferenceElement}
      >
        <span className="text-normal text-sm text-white">{text}</span>
        <Arraw expanded={expanded} />
      </button>
      <CSSTransition in={expanded} timeout={300} unmountOnExit nodeRef={nodeRef} classNames="fade">
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="z-10 hidden sm:block">
          <div
            ref={nodeRef}
            className="flex flex-col gap-large items-start py-large px-10 bg-white rounded"
            style={{
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
            }}
          >
            {subMenu.map(({ text: subText, path: subPath, isExternal: subIsExternal }) => {
              if (subPath) {
                return subIsExternal ? (
                  <a
                    key={subText}
                    target="_blank"
                    href={subPath}
                    rel="noreferrer"
                    className="text-normal text-xs hover:underline"
                    onClick={onToggle}
                  >
                    {subText}
                  </a>
                ) : (
                  <Link key={subText} to={subPath} className="text-normal text-xs hover:underline" onClick={onToggle}>
                    {subText}
                  </Link>
                );
              }
              return null;
            })}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

const Arraw = ({ expanded }: { expanded?: boolean }) => (
  <div
    className={`border-transparent border-t-[10px] border-t-black border-x-[7px] transition-transform duration-150 sm:duration-300 sm:border-t-white`}
    style={{ transform: `${expanded ? "rotateX(180deg)" : "rotateX(0)"}` }}
  />
);
