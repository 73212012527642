const check = (headers: number[]) => {
  return (buffers: Uint8Array, options = { offset: 0 }) =>
    headers.every((header, index) => header === buffers[options.offset + index]);
};

const isJPG1 = check([0xff, 0xd8, 0xff, 0xe0]);
const isJPG2 = check([0x4a, 0x46, 0x49, 0x46]);
const isJPG3 = check([0x45, 0x78, 0x69, 0x66]);
const isPNG = check([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);

const readBuffer = (file: File, start = 0, end = 2) =>
  new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file.slice(start, end));
  });

export const isValidUploadImg = async (file: File) => {
  const uint8ArrayJpg = new Uint8Array(await readBuffer(file, 0, 4));
  if (isJPG1(uint8ArrayJpg) || isJPG2(uint8ArrayJpg) || isJPG3(uint8ArrayJpg)) {
    return true;
  }
  const uint8ArrayPng = new Uint8Array(await readBuffer(file, 0, 8));
  if (isPNG(uint8ArrayPng)) {
    return true;
  }
  return false;
};
