import isNaN from "lodash/isNaN";

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function isEmpty(v: any | any[]): v is null | undefined {
  return v === null || v === undefined || v === "" || isNaN(v) || (v && v.length === 0);
}

export function isEmptyIncludeZero(v: any | any[]): boolean {
  return isEmpty(v) || v === "0" || v === 0;
}

export function shortenString(str: string, chars = 6): string {
  if (str.length < chars * 2) {
    return str;
  }
  return `${str.substring(0, chars)}...${str.substring(str.length - chars)}`;
}

export function capitalizeWords(str: string) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
