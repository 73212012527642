import { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useApp } from "src/hooks";
import { notification } from "src/utils/notification";
import { AppSpin } from "./AppSpin";

interface Props {
  children: JSX.Element;
  needEmail?: boolean;
  needAdminRole?: boolean;
  needVerifyEmail?: boolean;
}

export const PrivateRoute = ({ children, needEmail, needAdminRole, needVerifyEmail }: Props) => {
  const location = useLocation();
  const { updatingUserInfo, userInfo, isAuth } = useApp();

  const organization = useMemo(
    () => (userInfo?.organizations?.length ? userInfo.organizations[0] : null),
    [userInfo?.organizations]
  );

  if (!userInfo && !updatingUserInfo && !isAuth) {
    return <Navigate to="/login" replace state={{ ...location, ...location.state }} />;
  }

  if (userInfo) {
    if (needEmail && !userInfo.email) {
      notification.warn({ description: "Need setup an email" });
      return <Navigate to={"/setting"} />;
    }

    if (needAdminRole && organization?.role !== "admin") {
      notification.warn({ description: "Need admin role" });
      return <Navigate to={"/setting"} />;
    }

    if (needVerifyEmail && userInfo.email && !userInfo.effective) {
      return <Navigate to="/need_verify_email" replace state={{ ...location, ...location.state }} />;
    }

    return children;
  }

  return updatingUserInfo ? (
    <div className="absolute top-0 bottom-0 left-0 right-0 m-auto flex items-center justify-center z-10 bg-black-800/10 rounded">
      <AppSpin />
    </div>
  ) : (
    <span className="text-semibold text-sm">Failed to get user info</span>
  );
};
