import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useApp } from "src/hooks";

export const useAnchorScroll = () => {
  const { hash } = useLocation();
  const { anchorScrollCount } = useApp();

  useEffect(() => {
    if (hash && anchorScrollCount) {
      setTimeout(() => {
        const element = document.getElementById(hash.slice(1));
        if (element) {
          element.scrollIntoView();
        }
      }, 100);
    }
  }, [hash, anchorScrollCount]);

  return null;
};
