import "./style.css";

export const AppSpin = () => {
  const length = 9;
  const ascIIStart = 97;
  const ids = new Array(length).fill(0).map((_, index) => String.fromCharCode(ascIIStart + index));

  return (
    <div className="anime flex items-center justify-center">
      <ul>
        {ids.map((item, index) => (
          <li key={index} id={item} style={{ background: "linear-gradient(341deg, #FF99B9 8.41%, #D030DD 91.59%)" }} />
        ))}
      </ul>
    </div>
  );
};
