import { AppButton } from "src/components/AppButton";
import { Link, Outlet, useNavigate } from "react-router-dom";
import logo from "src/assets/images/app-logos/open_platform_logo_5.svg";
import subscanOpenPlatform from "src/assets/images/subscan_open_platform.svg";
import emailIcon from "src/assets/images/icon_email.svg";

interface Props {
  type: "login" | "signup";
}

const AppLoginSignupLayout = ({ type }: Props) => {
  return (
    <>
      {/* PC */}
      <div>
        <Header type={type} />
        <Content />
        <Footer />
      </div>

      {/* Mobile */}
      {/* <div className="md:hidden p-large">
        <span className="text-semibold text-lg">For a better experience, please visit via PC.</span>
      </div> */}
    </>
  );
};

const Content = () => {
  return (
    <div
      className="relative bg-[#FBFBFD] overflow-hidden flex items-center justify-center"
      style={{ height: "calc(100vh - 3.125rem - 3.125rem)" }}
    >
      <div
        className="absolute -right-32 -top-32 rounded-full"
        style={{
          width: "660px",
          height: "660px",
          background: "linear-gradient(315deg, #FF0050 0%, #7000FF 71.44%, #0027FF 100%)",
          opacity: "0.1",
          filter: "blur(67.957px)",
          mixBlendMode: "normal",
        }}
      />
      <div
        className="absolute -left-32 -bottom-32 rounded-full"
        style={{
          width: "660px",
          height: "660px",
          background: "linear-gradient(315deg, #FF0050 0%, #7000FF 71.44%, #0027FF 100%)",
          opacity: "0.1",
          filter: "blur(67.957px)",
          mixBlendMode: "normal",
        }}
      />

      <div className="flex flex-col lg:flex-row items-center gap-[5.25rem] z-10">
        <img className="hidden lg:block" alt="" src={subscanOpenPlatform} />
        <Outlet />
      </div>
    </div>
  );
};

const Header = ({ type }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white">
      <div className="px-2.5 lg:px-0 container mx-auto max-w-[75rem] h-[3.125rem] flex items-center justify-between">
        <Link to="/" className="transition-transform hover:scale-105 active:scale-95">
          <img alt="subscan platform" src={logo} />
        </Link>
        <div className="flex items-center gap-large">
          {type === "login" ? (
            <>
              <span className="text-normal text-sm">Login</span>
              <AppButton
                className="h-[1.5625rem] flex px-small lg:px-large rounded"
                textClassName="text-normal text-sm"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Sign up
              </AppButton>
            </>
          ) : (
            <>
              <AppButton
                className="h-[1.5625rem] flex px-small lg:px-large rounded"
                textClassName="text-normal text-sm"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </AppButton>
              <span className="text-normal text-sm">Sign up</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="bg-white px-2.5 lg:px-0">
      <div className="container mx-auto max-w-[75rem] h-[3.125rem] flex items-center justify-between">
        <span className="text-normal text-xs text-b2">Subscan © 2023 - Developed By Subscan Team</span>
        <div className="flex items-center gap-large">
          <a
            rel="noopener noreferrer"
            href="https://www.subscan.io/privacy"
            target="_blank"
            className="text-normal text-xs text-b2 clickable hover:underline"
          >
            Privacy Policy
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.subscan.io/term"
            target="_blank"
            className="text-normal text-xs text-b2 clickable hover:underline"
          >
            Terms of Use
          </a>
          <a href="mailto:api@subscan.io" className="clickable shrink-0">
            <img alt="..." src={emailIcon} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppLoginSignupLayout;
