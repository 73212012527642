const environment = process.env.REACT_APP_ENV === "production" ? "pro" : "dev";
let exports = { baseUrl: "", recaptchaKey: "", rpc: "", decimals: 1, editorUrl: "" };

if (environment === "dev") {
  exports = {
    baseUrl: "https://platform-staging.subscan.io",
    recaptchaKey: "6Lcw-EwgAAAAAF3Rulf3WllhA_Tp6dPiZfVRwpD1",
    rpc: "wss://westend-rpc.polkadot.io",
    decimals: 12,
    editorUrl: "/lowcode/editor",
  };
} else if (environment === "pro") {
  exports = {
    baseUrl: "https://platform.subscan.io",
    recaptchaKey: "6Ldf90wgAAAAABo5Ukx2ClTjLDeiHBIrh2xUjC1P",
    rpc: "wss://rpc.polkadot.io",
    decimals: 10,
    editorUrl: "/lowcode/editor",
  };
}

export const BIGNUMBER_FMT = {
  prefix: "",
  decimalSeparator: ".",
  groupSeparator: ",",
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: " ",
  fractionGroupSize: 0,
  suffix: "",
};

export default exports;
