import { Link, NavLink, useLocation } from "react-router-dom";
import { User } from "src/components/User";
import logo from "../assets/images/app-logos/open_platform_logo_5.svg";
import { useEffect, useRef, useState } from "react";
import { AppButton } from "./AppButton";
import { Flex } from "src/ui";
import { AppDrawer } from "./AppDrawer";
import menuIcon from "src/assets/images/icon_menu_black.svg";
import { AppSidebar } from "./AppSidebar";

export const AppHeader = () => {
  const { pathname, state } = useLocation();
  const pathnameRef = useRef(pathname);
  const nodeARef = useRef(null);
  const nodeBRef = useRef(null);
  const [node, setNode] = useState<"a" | "b">("a");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (pathnameRef.current !== pathname) {
      pathnameRef.current = pathname;
      setNode((prev) => (prev === "a" ? "b" : "a"));
    }
  }, [pathname]);

  const routers = pathname.slice(1).split("/");
  if (!Number.isNaN(Number(routers[routers.length - 1]))) {
    routers.pop();
  }
  const nodeRef = node === "a" ? nodeARef : nodeBRef;

  return (
    <>
      <div className="md:h-[3.75rem] gap-y-2.5 py-2.5 md:py-0 px-5 flex flex-col md:flex-row flex-none items-center justify-between bg-[#FBFBFD]">
        <Flex className="w-full md:w-auto justify-between">
          <NavLink
            to="/"
            className={"overflow-hidden transition-transform duration-300 active:scale-95"}
            style={{ height: 25, width: 220 }}
          >
            <img alt="Subscan logo" src={logo} className="transition-transform duration-300" />
          </NavLink>
          <button
            className="text-red md:hidden hover:outline-none focus-visible:outline-none w-fit h-fit transition-transform active:scale-95"
            onClick={() => setOpen(true)}
          >
            <img alt="menu" src={menuIcon} />
          </button>
        </Flex>

        <div className="flex items-center gap-x-[30px]">
          {/* user */}
          <User />
          <a
            href="https://www.subscan.io/"
            className="text-sm text-normal transition-opacity hover:opacity-80 active:opacity-60"
          >
            <AppButton
              variant="contained"
              className="h-9 px-small md:px-large rounded"
              textClassName="text-normal text-sm"
            >
              Back to Explorer
            </AppButton>
          </a>
        </div>
      </div>
      <AppDrawer title="menu" open={open} maskClosable onClose={() => setOpen(false)}>
        <div className="h-full flex flex-col items-start justify-between">
          <div className="w-full py-10 px-4 overflow-y-auto">
            <AppSidebar onFinish={() => setOpen(false)} />
          </div>
          <div className="flex justify-center w-full">{/* <Media /> */}</div>
        </div>
      </AppDrawer>
    </>
  );
};

const Item = ({
  to,
  router,
  index,
  isActive,
  publishId,
}: {
  to: string;
  router: string;
  index: number;
  isActive: boolean;
  publishId?: number;
}) =>
  isActive ? (
    <span>
      {index ? <span className="text-sm text-normal">&nbsp;/&nbsp;</span> : ""}
      <span className={`capitalize text-sm text-semibold`}>{router.replace(/_/g, " ")}</span>
      {publishId && <span className="text-sm text-semibold">&nbsp;#{publishId}</span>}
    </span>
  ) : (
    <Link to={to} className="transition-transform duration-300 hover:scale-105 active:scale-95">
      {index ? <span className="text-sm text-normal">&nbsp;/&nbsp;</span> : ""}
      <span className={`capitalize text-sm text-normal`}>{router.replace(/_/g, " ")}</span>
    </Link>
  );
